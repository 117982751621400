<template>
  <div class="paperForm">
    <!--上传试卷弹窗-->
    <!-- :title="(indexPaper.id ? '修改' : '创建') + '试卷资源'" -->
    <el-dialog
      class="upload-exam-paper-dialog"
      title="上传试卷"
      :visible.sync="uploadDialogVisible"
      width="660px"
      :close-on-click-modal="false"
    >
      <el-radio-group v-model="type" class="radio-group">
        <el-radio-button :label="1">模板导卷</el-radio-button>
        <el-radio-button :label="2">上传裁切</el-radio-button>
      </el-radio-group>
      <template v-if="type == 1">
        <div class="error-tip">
          <img src="../../../../assets/imgs/exam/check.png" alt="" />
          文件仅支持.docx，请勿手动将文件格式后缀修改为.docx，否则将解析失败！
        </div>
        <div class="tips">
          按模板编制试卷，然后上传，实现智能导入。
          <el-link type="primary" underline @click="downloadTemplate"
            >下载word模板</el-link
          >
        </div>
        <el-upload
          ref="upload"
          class="upload-demo"
          :action="uploadAvatarURL"
          :headers="uploadHeader"
          :file-list="fileList"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          :on-change="uploadChange"
          :on-remove="uploadRemove"
          style="margin-bottom: 18px; width: 100%"
          name="file"
          :auto-upload="false"
          accept=".doc,.docx"
          drag
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将.docx文件点击或拖拽到这里上传</div>
        </el-upload>
      </template>
      <el-form
        v-if="type == 2"
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
        label-width="0"
      >
        <div class="form-item-box">
          <el-form-item label="试卷名称：" prop="name">
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入试卷名称"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item-box two-box">
          <el-form-item label="学段：" prop="level">
            <el-select
              v-model="ruleForm.level"
              placeholder="请选择学段"
              @change="changeLevel()"
            >
              <el-option
                v-for="item in levelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级：" prop="resGradeId">
            <el-select
              v-if="gradeOptions && gradeOptions.length > 0"
              v-model="ruleForm.resGradeId"
              placeholder="请选择年级"
            >
              <el-option
                v-for="item in gradeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item-box two-box">
          <el-form-item label="科目：" prop="subjectId">
            <el-select v-model="ruleForm.subjectId" placeholder="请选择科目">
              <el-option
                v-for="item in subjectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型：" prop="types">
            <el-cascader
              v-model="ruleForm.types"
              placeholder="请选择类型"
              :options="sourceTypeItem"
              filterable
            >
            </el-cascader>
          </el-form-item>
        </div>

        <div class="form-item-box two-box">
          <el-form-item label="地区：" prop="locationArr">
            <!-- {{ ruleForm.locationArr }} -->
            <el-cascader
              v-model="ruleForm.locationArr"
              placeholder="请选择地区"
              :options="locationOptions"
              filterable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="年份：" prop="year">
            <el-date-picker
              v-model="ruleForm.year"
              type="year"
              placeholder="请选择年份"
              value-format="yyyy"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="form-item-box two-box">
          <el-form-item v-if="!indexPaper.id" label="分享：" prop="share">
            <el-select v-model="ruleForm.share" placeholder="请选择">
              <el-option
                v-for="item in shareOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="来源：" prop="resSchoolId">
            <el-select
              v-model="ruleForm.resSchoolId"
              filterable
              placeholder="请选择来源"
            >
              <el-option
                v-for="item in eliteSchool"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          :disabled="type == 1 && fileList.length == 0"
          @click="uploadExamPaper"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getObj, resschoolList } from "@/core/api/school/school";
import { getStore } from "@/core/util/store";
import { filterSubject } from "@/core/util/util";
import {
  examTypeOptions,
  eliteSchoolOptions,
  levelOptions,
  gradeHighOptions,
  gradeMiddleOptions,
  subjectOptions,
  gradeOptions,
  primaryOptions,
} from "@/core/util/constdata";
import { listCommonTree } from "@/core/api/resource/knowledgePointsPaper";
import { upload, editExamPaper } from "@/core/api/resource/exampaper";
import { templateModel } from "@/core/api/homework/index";
import { mapGetters } from "vuex";
export default {
  name: "PaperForm",
  data() {
    let examTypeOptionsList = [...examTypeOptions];
    const storage = localStorage.getItem("jzjx-schoolInfo");
    const schoolInfo = storage ? JSON.parse(storage).content : {};
    const application = schoolInfo.application || "";
    const hasHomework = application.indexOf("221") != -1;
    if (!hasHomework) {
      examTypeOptionsList = examTypeOptions.filter(
        (item) => item.label != "作业"
      );
    }
    return {
      indexPaper: {},
      subjectId: null,
      level: null,
      loading: false,
      uploadDialogVisible: false,
      gradeHighOptions: gradeHighOptions,
      gradeMiddleOptions: gradeMiddleOptions,
      subjectOptions: subjectOptions,
      examTypeOptions: examTypeOptionsList,
      eliteSchoolOptions: eliteSchoolOptions,
      gradeOptions: gradeOptions,
      levelOptions: levelOptions(),
      uploadHeader: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": getStore({ name: "school_id" }),
      },
      shareOptions: [
        { value: 0, label: "精品卷库" },
        { value: 1, label: "校本卷库" },
        { value: 2, label: "不分享" },
      ],
      sourceTypeItem: [],
      rules: {
        name: [{ required: true, message: "请输入试卷名称", trigger: "blur" }],
        level: [{ required: true, message: "请选择学段", trigger: "change" }],
        resGradeId: [
          { required: true, message: "请选择年级", trigger: "change" },
        ],
        resSchoolId: [
          { required: true, message: "请选择来源", trigger: "change" },
        ],
        subjectId: [
          { required: true, message: "请选择科目", trigger: "change" },
        ],
        types: [{ required: true, message: "请选择类型", trigger: "change" }],
        province: [{ required: true, message: "请选择省", trigger: "change" }],
        city: [{ required: true, message: "请选择市", trigger: "change" }],
        district: [{ required: true, message: "请选择区", trigger: "change" }],
        locationArr: [
          { required: true, message: "请选择省市区", trigger: "change" },
        ],
        year: [{ required: true, message: "请选择年份", trigger: "change" }],
        share: [{ required: true, message: "请选择", trigger: "change" }],
      },
      locationOptions: [],
      eliteSchool: [],
      ruleForm: {
        name: "",
        level: "",
        resGradeId: "",
        subjectId: "",
        type: "",
        types: [],
        province: "", // 保存的行政区划代码
        city: "", // 保存的行政区划代码
        district: "", // 保存的行政区划代码
        year: "",
        share: 0,
        resSchoolId: "",
        locationArr: [],
      },
      type: 1,
      isOver: false,
      notWord: false,
      disabledSubmit: false,
      fileObj: {},
      fileList: [],
      uploadAvatarURL: "",
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  created() {
    this.getlistCommonTree();
    this.initType();
    this.getSchool();
    this.subjectId = this.getSubjectId;
    this.level = this.getLevelId;
  },

  methods: {
    async downloadTemplate() {
      const res = await templateModel({ subjectId: this.subjectId });
      const { href } = this.$router.resolve({
        path: "/downFile",
        query: {
          url: res.data.data,
          name: "试卷上传模板",
          type: 1,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    uploadRemove() {
      this.fileList = [];
    },
    uploadSuccess(res) {
      if (res.data == 0) {
        this.$message({
          type: "warning",
          message: "解析失败",
          showClose: true,
        });
        this.loading = false;

        this.fileList = [];
        return false;
      }
      if (res.code != 0) {
        this.$message({
          type: "warning",
          message: res.msg,
          showClose: true,
        });
        this.fileList = [];
        this.loading = false;

        this.disabledSubmit = true;
      } else {
        this.$router.push({
          path: "/homework/paperDetails",
          query: { id: res.data },
        });
        this.fileList = [];
        this.$message({
          type: "success",
          message: "上传成功",
          showClose: true,
        });
      }
    },
    uploadError() {
      this.disabledSubmit = true;
      this.loading = false;
    },
    uploadChange(file) {
      if (!this.fileObj || this.fileObj.uid != file.uid) {
        this.isOver = file.size / 1024 / 1024 > 5;
        var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
        this.notWord = testmsg !== "doc" && testmsg !== "docx";
        if (this.isOver || this.notWord) {
          this.disabledSubmit = true;
          this.fileObj = null;
          this.$refs.upload.clearFiles();
        } else {
          this.disabledSubmit = false;
          this.fileObj = { ...file };
          this.fileList = [file];
          this.notWord = false;
          this.isOver = false;
        }
      }
    },
    getSchool() {
      let schoolInfo = getStore({ name: "schoolInfo" });
      this.schoolInfo = schoolInfo;
      resschoolList().then((res) => {
        let arr = res.data.data.filter((item) => item.id == schoolInfo.id);
        if (arr.length == 0) {
          res.data.data = [{ id: schoolInfo.id, name: schoolInfo.name }].concat(
            res.data.data
          );
        }

        this.eliteSchool = res.data.data;
      });
    },
    initType() {
      let { isImportant } = getStore({
        name: "schoolInfo",
      });

      let arr = [
        {
          label: "常规资源",
          value: 0,
          children: this.examTypeOptions,
        },
      ];
      if (isImportant == "1") {
        arr = [
          {
            label: "名校资源",
            value: 1,
            children: this.eliteSchoolOptions,
          },
        ];
      }
      if (this.$parent.getLevelId == 1) {
        this.sourceTypeItem = [
          {
            label: "小考真题",
            value: 11,
          },
        ].concat(arr);
      }
      if (this.$parent.getLevelId == 2) {
        this.sourceTypeItem = [
          {
            label: "中考真题",
            value: 12,
          },
        ].concat(arr);
      }
      if (this.$parent.getLevelId == 3) {
        this.sourceTypeItem = [
          {
            label: "高考真题",
            value: 13,
          },
        ].concat(arr);
      }
    },

    getlistCommonTree() {
      let data = { maxType: 2 };
      listCommonTree(data).then((res) => {
        this.locationOptions = res.data.data.map((item) => {
          item.value = item.code;
          item.label = item.name;
          item.children.map((index) => {
            index.value = index.code;
            index.label = index.name;
            if (index.children.length == 0) {
              delete index.children;
            } else {
              index.children.map((i) => {
                i.value = i.code;
                i.label = i.name;
                if (i.children.length == 0) {
                  delete i.children;
                }
              });
              index.children = [
                {
                  value: "0",
                  label: "全区",
                },
              ].concat(index.children);
            }
          });
          item.children = [
            {
              value: "0",
              label: "全市",
            },
          ].concat(item.children);
          return item;
        });
        this.locationOptions = JSON.parse(JSON.stringify(this.locationOptions));
      });
    },
    editForm(data) {
      editExamPaper(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "修改成功!",
            type: "success",
          });
          //触发本地方法  更新当前页面科目
          let editObject = {
            levelId: this.ruleForm.level,
            subjectId: this.ruleForm.subjectId,
          };
          this.$store.commit("setEditObject", editObject);
          this.uploadDialogVisible = false;
          this.$parent.sourceType = data.category;
          this.$parent.getList();
          setTimeout(() => {
            this.loading = false;
          }, 500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    uploadExamPaper() {
      this.loading = true;
      if (this.type == 1) {
        if (this.notWord) {
          this.$message({
            type: "error",
            message: "请选择正确的文件格式",
            showClose: true,
          });
          this.loading = false;
          return false;
        }
        if (this.isOver) {
          this.$message({
            type: "error",
            message: "文件大小不能超过5M",
            showClose: true,
          });
          this.loading = false;
          return false;
        }
        this.uploadAvatarURL = `/homework/homework/upload/homework?level=${this.level}&subjectId=${this.subjectId}`;
        this.$nextTick(() => {
          this.$refs.upload.submit();
        });
      } else {
        let data = JSON.parse(JSON.stringify(this.ruleForm));

        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let province = data.locationArr[0];
            let city = data.locationArr[1];
            let district = data.locationArr[2];
            data.category = data.types[0];

            if (data.types[1]) {
              data.type = data.types[1];
            } else {
              data.type = 0;
            }

            this.locationOptions.map((item) => {
              if (item.value == province) {
                data.province = item.label + "-" + province;
                if (!item.children) return;
                item.children.map((index) => {
                  if (index.value == city) {
                    data.city = index.label + "-" + city;
                    if (!index.children) return;
                    index.children.map((i) => {
                      if (i.value == district) {
                        data.district = index.label + "-" + district;
                      }
                    });
                  }
                });
              }
            });
            if (this.indexPaper.id) {
              data.id = this.indexPaper.id;
              delete data.share;
            }
            this.eliteSchool.map((item) => {
              if (item.id == this.ruleForm.resSchoolId) {
                data.resSchoolName = item.name;
              }
            });

            if (this.indexPaper.id) {
              this.editForm(data);
            } else {
              this.subAddForm(data);
            }
          } else {
            this.loading = false;
          }
        });
      }
    },

    subAddForm(data) {
      upload(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "新增成功!",
            type: "success",
          });
          //触发本地方法  更新当前页面科目
          let editObject = {
            levelId: this.ruleForm.level,
            subjectId: this.ruleForm.subjectId,
          };
          this.$store.commit("setEditObject", editObject);
          this.uploadDialogVisible = false;
          this.$parent.sourceType = data.category;
          this.$parent.getList();
          setTimeout(() => {
            this.loading = false;
          }, 500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 修改学段后，修改年级
    changeLevel() {
      if (this.ruleForm.level == "2") {
        this.gradeOptions = gradeMiddleOptions;
      } else if (this.ruleForm.level === "3") {
        this.gradeOptions = gradeHighOptions;
      } else {
        this.gradeOptions = primaryOptions;
      }

      this.ruleForm.resGradeId = "";
      this.ruleForm.subjectId = "";
      this.subjectOptions = filterSubject(this.ruleForm.level);
    },
    init(paper) {
      Object.assign(this.ruleForm, this.$options.data().ruleForm);
      this.indexPaper = {};
      this.uploadDialogVisible = true;

      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        this.ruleForm.resSchoolId = this.schoolInfo.id;
        if (paper) {
          this.indexPaper = paper;
          paper = JSON.parse(JSON.stringify(paper));
          let { city, district, province } = paper;

          if (province) {
            this.ruleForm.locationArr.push(province.split("-")[1]);
          }
          if (city) {
            this.ruleForm.locationArr.push(city.split("-")[1]);
          }
          if (district) {
            this.ruleForm.locationArr.push(district.split("-")[1]);
          }

          this.ruleForm.level = paper.level.toString();
          this.changeLevel();
          this.ruleForm.types = [Number(paper.category)];
          if (paper.type) {
            this.ruleForm.types.push(Number(paper.type));
          }
          this.ruleForm.year = paper.year.toString();
          this.ruleForm.resGradeId = paper.resGradeId;
          this.ruleForm.subjectId = paper.subjectId;
          this.ruleForm.resSchoolId = paper.resSchoolId;
          this.ruleForm.name = paper.name;
          //   Object.assign(this.ruleForm, paper);
        } else {
          if (this.$parent.getLevelId) {
            this.ruleForm.level = this.$parent.getLevelId;
            this.changeLevel();
          }
          if (this.$parent.getSubjectId) {
            this.ruleForm.subjectId = this.$parent.getSubjectId;
          }

          let schoolId = getStore({ name: "school_id" });
          if (schoolId && schoolId > 0) {
            this.getSchoolMsg(schoolId);
          }
        }
      });
    },
    getSchoolMsg(schoolId) {
      getObj(schoolId).then((response) => {
        let { province, city, district } = response.data.data;
        this.ruleForm.locationArr = [province, city, district];
        // console.log(this.ruleForm.locationArr);
        // this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
      });
    },
  },
};
</script>
<style lang="scss">
.el-cascader-panel {
  .el-checkbox {
    margin-bottom: 0;
  }
}
</style>
<style lang="scss" scoped>
.paperForm {
  .el-form-item {
    display: flex;

    ::v-deep {
      .el-form-item__label {
        display: block;
        width: auto !important;
        flex-shrink: 0;
      }

      .el-form-item__content {
        width: 100%;
      }
    }
  }

  .el-cascader {
    width: 100%;

    ::v-deep {
      .el-cascader__tags {
        flex-wrap: unset;
      }

      .el-input__inner {
        height: 32px !important;
      }
    }
  }
}
::v-deep .el-upload {
  width: 100%;
}
::v-deep .el-upload-dragger {
  width: 100%;
}
.tips {
  margin: 18px 0;
}
.radio-group {
  margin-bottom: 18px;
  label {
    margin-bottom: 0;
  }
}
.error-tip {
  box-sizing: border-box;
  padding: 0 18px;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  text-align: left;
  color: #292626;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 18px;
  background: #fff3f3;
  img {
    margin-right: 3px;
  }
}
</style>
